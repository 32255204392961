import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

import * as S from "./cookieDisclaimer.css"
import * as T from "../UI/Typography"
import { globalWindow } from "../../utils/constants"

export interface ICookie {
  contentYaml: {
    cookiePolicy: { title: string; cta: string; accept: string; text: string }
  }
}

const CookieDisclaimer = () => {
  const {
    contentYaml: { cookiePolicy },
  }: ICookie = useStaticQuery(query)
  const [accepted, setAccepted] = React.useState(true)

  React.useEffect(() => {
    if (globalWindow) {
      const cookieData =
        (global as any).window.localStorage.getItem(
          "ethereum--world__cookiePolicy"
        ) === "true"

      setAccepted(cookieData)
    }
  }, [])

  const handleCookie = () => {
    if (globalWindow) {
      const cookieData = (global as any).window.localStorage.setItem(
        "ethereum--world__cookiePolicy",
        "true"
      )

      setAccepted(true)
    }
  }

  return (
    <S.Wrapper accepted={accepted}>
      <S.Title>{cookiePolicy.title} </S.Title>
      <T.TestimonialText>
        {cookiePolicy.text}{" "}
        <S.CookieLink
          style={{ verticalAlign: "bottom" }}
          color="blue"
          to="/privacy-policy/"
        >
          {cookiePolicy.cta}
        </S.CookieLink>
      </T.TestimonialText>
      <S.Leave onClick={handleCookie} aria-label="Accept cookies" />
    </S.Wrapper>
  )
}

export default CookieDisclaimer

const query = graphql`
  query cookieDisclaimer {
    contentYaml(homepage: { button: { ne: null } }) {
      cookiePolicy {
        title
        text
        cta
        accept
      }
    }
  }
`
