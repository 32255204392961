import * as React from "react"
import Img, { FluidObject } from "gatsby-image"

import styled from "../../styled"

export interface IconProp {
  asset?: FluidObject
  alt?: string
}

const IconWrapper = styled.div`
  width: 36px;
  margin-right: 12px;
  max-height: 36px;
`

const Icon: React.FC<IconProp> = ({ asset, alt, ...props }) => {
  return (
    <IconWrapper {...props}>
      <Img fluid={asset} alt={alt} />
    </IconWrapper>
  )
}

export default Icon
