import * as React from "react"
import styled from "../styled"
import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"

import getColumnSize from "../../utils/getColumnSize"
import { SubTitle, Text, Description } from "../UI/Typography"
import Icon from "../UI/Icon/Icon"
import LinkWrap from "../UI/Link"

const AkashaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: unset;
  margin-top: 47.5px;
  padding-top: 47.5px;

  ${({ theme }) => theme.media.above("large")} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 63.5px;
    padding-top: 63.5px;
  }
`
const CustomIcon = styled(Icon)`
  border-radius: 3.6px;
  overflow: hidden;
`

const LineSeparator = styled.div`
  border-top: 1px dashed ${({ theme }) => theme.colors.grayscale.white};
  opacity: 0.48;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
`

const ItemLink = styled(LinkWrap)`
  cursor: pointer;
`

const AkashaItem = styled.div`
  width: ${getColumnSize(12)};
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.above("large")} {
    width: calc(33.3333% - 15px);
    margin-bottom: 0;
    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Top = styled.div`
  display: flex;
`

const By = styled(SubTitle)`
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grayscale.mediumLight};
`

export const TitleContent = styled(Description)`
  margin-bottom: 0.375rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayscale.white};
`

export const TextContent = styled(Text)`
  position: relative;
  font-size: 0.875rem;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.grayscale.lightMedium};
`

export const TitleWrapper = styled.div`
  position: relative;
`

interface Akasha {
  contentYaml: {
    akasha_section: Array<{
      subtitle: string
      text: string
      title: string
      link?: string
      icon: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }>
  }
}

export const query = graphql`
  query AkashaBy {
    contentYaml(homepage: { button: { ne: null } }) {
      akasha_section {
        subtitle
        text
        title
        link
        icon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const AkashaBy: React.FC = () => {
  const data: Akasha = useStaticQuery(query)
  return (
    <AkashaWrapper>
      <LineSeparator />
      {data.contentYaml.akasha_section.map(item =>
        item.link ? (
          <AkashaItem key={item.link}>
            <ItemLink to={item.link}>
              <Top>
                <CustomIcon asset={item.icon.childImageSharp.fluid} />
                <TitleWrapper>
                  <By color="blue">{item.subtitle}</By>
                  <TitleContent color="black">{item.title}</TitleContent>
                </TitleWrapper>
              </Top>
            </ItemLink>
            <TextContent>{item.text}</TextContent>
          </AkashaItem>
        ) : (
          <AkashaItem>
            <Top>
              <CustomIcon asset={item.icon.childImageSharp.fluid} />
              <TitleWrapper>
                <By color="blue">{item.subtitle}</By>
                <TitleContent color="black">{item.title}</TitleContent>
              </TitleWrapper>
            </Top>
            <TextContent>{item.text}</TextContent>
          </AkashaItem>
        )
      )}
    </AkashaWrapper>
  )
}

export default AkashaBy
