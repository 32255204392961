import * as React from "react"
import styled, { css } from "../styled"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

import { Container } from "../UI/Grid/Grid"
import { Text, Title } from "../UI/Typography"
import { Button } from "../UI"
import getColumnSize from "../../utils/getColumnSize"
import LeaveArrowBlack from "../../../content/images/leave-icon-black.png"
import LeaveArrow from "../../../content/images/leave-icon.png"

const WorldWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 5rem 0;
  flex-direction: column;

  ${({ theme }) => theme.media.above("large")} {
    flex-direction: row;
    margin: 174px 0 264px;
  }
`

const ImageWrapper = styled.div`
  width: ${getColumnSize(12)};
  margin-left: ${getColumnSize(0)};
  margin-bottom: 56px;
  transform: rotateY(180deg);

  .gatsby-image-wrapper {
    max-width: 100%;
    margin: 0 auto;
    ${({ theme }) => theme.media.above("small")} {
      max-width: 70%;
    }
    ${({ theme }) => theme.media.above("medium")} {
      max-width: 468px;
    }
  }

  ${({ theme }) => theme.media.above("small")} {
    width: ${getColumnSize(10)};
    margin-left: ${getColumnSize(1)};
  }
  ${({ theme }) => theme.media.above("large")} {
    width: ${getColumnSize(4)};
    margin-left: ${getColumnSize(1)};
    margin-bottom: 0;
  }
`

const ContentWrapper = styled.div`
  width: ${getColumnSize(12)};
  margin-left: ${getColumnSize(0)};

  ${({ theme }) => theme.media.above("small")} {
    width: ${getColumnSize(10)};
    margin-left: ${getColumnSize(1)};
  }
  ${({ theme }) => theme.media.above("large")} {
    width: ${getColumnSize(4)};
    margin-left: ${getColumnSize(1)};
  }
`

const TitleContent = styled(Title)`
  margin-top: 0;
  margin-bottom: 1rem;
  max-width: 265px;
  max-width: 275px;

  strong {
    font-weight: 500;
    background: ${({ theme: { colors } }) => `${colors.blue.middle}20`};
  }

  ${({ theme }) => theme.media.above("large")} {
    max-width: 265px;
    margin-top: 5rem;
  }
`

const TextContent = styled(Text)`
  margin-bottom: 0.325rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 3.5rem;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.above("medium")} {
    justify-content: center;
    margin: 0 auto;
    max-width: 18.75rem;
  }

  ${({ theme }) => theme.media.above("large")} {
    justify-content: flex-start;
    margin-bottom: 0;
    max-width: 100%;
    flex-direction: row;
  }
`

const ButtonItem = styled(Button)`
  margin-right: 0;
  margin-bottom: 1rem;
  text-align: center;

  ${({ ghost }) =>
    ghost &&
    css`
      color: ${({ theme: { colors } }) => colors.grayscale.dark};
      border-color: ${({ theme: { colors } }) => colors.grayscale.mediumLight};

      &:hover {
        border-color: ${({ theme: { colors } }) => colors.grayscale.dark};
        background-color: transparent;
        color: ${({ theme: { colors } }) => colors.grayscale.dark};
      }
    `}

  ${({ theme }) => theme.media.above("large")} {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
`

const GoesOutLink = styled.span`
  position: relative;
  padding-right: 12px;
  &:after {
    content: "";
    position: absolute;
    display: block;
    background-image: url(${LeaveArrow});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 6px;
    width: 8px;
    transition: ${({ theme: { transition } }) => transition.ease(200)};
    top: 0;
    right: 0;
    bottom: 0;
  }
`

const GoesOutLinkBlack = styled(GoesOutLink)`
  &:after {
    background-image: url(${LeaveArrowBlack});
  }
`

type ButtonType = Array<{
  [key: string]: boolean
}>

const YourWorld: React.FC = () => {
  const data = useStaticQuery<WorldType>(query)
  const content = data.contentYaml.your_world
  const buttonProps: ButtonType = [{ ghost: false }, { ghost: true }]

  return (
    <Container>
      <WorldWrapper>
        <ImageWrapper>
          <Img fluid={content.illustration.childImageSharp.fluid} />
        </ImageWrapper>
        <ContentWrapper>
          <TitleContent color="greyDark">
            <span dangerouslySetInnerHTML={{ __html: content.title }} />
          </TitleContent>
          <TextContent color="greyDark">
            {content.text
              .split("\n")
              .filter(Boolean)
              .map((item, index) => {
                return [
                  item,
                  <React.Fragment key={index}>
                    <br />
                    <br />
                  </React.Fragment>,
                ]
              })}
          </TextContent>
          <ButtonWrapper>
            {content.buttons.map((button, index) => {
              return buttonProps[index].ghost ? (
                <ButtonItem
                  key={button.button_title}
                  ghost={buttonProps[index].ghost}
                  href={button.button_url}
                >
                  <GoesOutLinkBlack>{button.button_title}</GoesOutLinkBlack>
                </ButtonItem>
              ) : (
                <ButtonItem
                  key={button.button_title}
                  ghost={buttonProps[index].ghost}
                  href={button.button_url}
                >
                  <GoesOutLink>{button.button_title}</GoesOutLink>
                </ButtonItem>
              )
            })}
          </ButtonWrapper>
        </ContentWrapper>
      </WorldWrapper>
    </Container>
  )
}

interface WorldType {
  contentYaml: {
    your_world: {
      text: string
      title: string
      buttons: Array<{
        button_title: string
        button_url: string
      }>
      illustration: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }
  }
}

export const query = graphql`
  query YourWorld {
    contentYaml(homepage: { button: { ne: null } }) {
      your_world {
        text
        title
        buttons {
          button_title
          button_url
        }
        illustration {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default YourWorld
