import styled, { css } from "../styled"
import { TextBold } from "../UI/Typography"
import Link from "../UI/Link"

export const Wrapper = styled.div<{ accepted: boolean }>`
  ${({ theme: { transition, colors }, accepted }) => css`
    position: fixed;
    bottom: 1em;
    right: 20px;
    z-index: 9999;
    max-width: 70em;
    box-shadow: 0 3px 14px 0 rgba(83, 98, 124, 0.06);
    padding: 1em;
    margin: 0 auto;
    margin-left: 20px;
    background: ${colors.grayscale.white};
    max-width: 406px;
    border-radius: 4px;
    transition: all ${transition.cubic()};
    transform: translateY(${accepted ? "150%" : 0});
    opacity: ${accepted ? 0 : 1};

    @media (max-width: 600px) {
      display: block;
      bottom: 0;
      font-size: 12px;
    }
  `}
`

export const Title = styled(TextBold)`
  margin-bottom: 5px;
`

export const CookieLink = styled(Link)`
  line-height: 18px;
`

export const Leave = styled.button`
  position: absolute;
  top: 17px;
  right: 17px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 4px;
    right: 0;
    height: 2px;
    width: 16px;
    background-color: ${({ theme }) => theme.colors.grayscale.dark};
    transition: ${({ theme }) => theme.transition.ease(200)};
    transform: rotate(-45deg) translate(-1px, 2px);
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 4px;
    right: 0;
    height: 2px;
    width: 16px;
    background-color: ${({ theme }) => theme.colors.grayscale.dark};
    transition: ${({ theme }) => theme.transition.ease(200)};
    transform: rotate(45deg) translate(-2px, -3px);
  }

  display: block;
`
