import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { motion, useViewportScroll, useTransform } from "framer-motion"

import { Container } from "../UI/Grid/Grid"

import * as S from "./Hero.css"
import { Button } from "../../components/UI"

const Hero = () => {
  const data: HeroProps = useStaticQuery(query)
  const content = data.contentYaml.homepage

  const { scrollYProgress } = useViewportScroll()
  const moveRight = useTransform(scrollYProgress, [0, 1], [0, -150])
  const rotateRight = useTransform(scrollYProgress, [0, 1], [0, 150])
  const pyramidMove = useTransform(scrollYProgress, [0, 1], [0, 200])
  const pyramidRotate = useTransform(scrollYProgress, [0, 1], [0, 40])
  const backMove = useTransform(scrollYProgress, [0, 1], [0, -10])
  const backRotate = useTransform(scrollYProgress, [0, 1], [0, 35])

  return (
    <S.Wrapper>
      <Container>
        <S.InnerWrapper>
          <S.Content>
            <S.Title>
              {content.title
                .split("\n")
                .filter(Boolean)
                .map((item, index) => {
                  return [
                    item,
                    <React.Fragment key={index}>
                      <br />
                    </React.Fragment>,
                  ]
                })}
            </S.Title>
            <S.TextContent>
              <div dangerouslySetInnerHTML={{ __html: content.text }} />
            </S.TextContent>
            <Button href={content.button_check_out.link}>
              {content.button_check_out.text}
            </Button>
          </S.Content>
          <S.ImageContainer>
            <S.Detail>
              <motion.div
                style={{
                  translateX: moveRight,
                  rotate: rotateRight,
                }}
              >
                <Img fluid={content.detail.childImageSharp.fluid} />
              </motion.div>
            </S.Detail>
            <S.ImageWrapper>
              <motion.div
                style={{
                  translateX: pyramidMove,
                  translateY: pyramidMove,
                  rotate: pyramidRotate,
                }}
              >
                <Img fluid={content.illustration.childImageSharp.fluid} />
              </motion.div>
            </S.ImageWrapper>
            <S.BackImageWrapper>
              <motion.div
                style={{
                  translateX: backMove,
                  rotate: backRotate,
                }}
              >
                <Img fluid={content.illustration_back.childImageSharp.fluid} />
              </motion.div>
            </S.BackImageWrapper>

            <S.DetailRight>
              <motion.div
                style={{
                  translateX: moveRight,
                  rotate: rotateRight,
                }}
              >
                <Img fluid={content.detail_right.childImageSharp.fluid} />
              </motion.div>
            </S.DetailRight>
          </S.ImageContainer>
        </S.InnerWrapper>
      </Container>
    </S.Wrapper>
  )
}

interface HeroProps {
  contentYaml: {
    homepage: {
      text: string
      title: string
      button_check_out: {
        link: string
        text: string
      }
      illustration: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
      illustration_back: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
      detail: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
      detail_top: {
        publicURL: string
      }
      detail_right: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }
  }
}

export const query = graphql`
  query Hero {
    contentYaml(homepage: { button: { ne: null } }) {
      homepage {
        text
        title
        button_check_out {
          link
          text
        }
        illustration {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        illustration_back {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        detail {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        detail_right {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        detail_top {
          publicURL
        }
      }
    }
  }
`
export default Hero
