import styled from "../styled"
import getColumnSize from "../../utils/getColumnSize"
import { MainTitle, Text } from "../UI/Typography"

export const Wrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 70px;
  z-index: 1;

  ${({ theme }) => theme.media.above("large")} {
    padding-top: 100px;
    padding-bottom: 240px;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: ${getColumnSize(12)};
  max-width: 335px;
  margin: 64px auto;

  ${({ theme }) => theme.media.above("large")} {
    width: ${getColumnSize(6)};
    transform: translate(0, -10px);
    margin: 0;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  transform: translate(30px, 50px);
  max-width: 220px;

  ${({ theme }) => theme.media.above("large")} {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 297px;
  }
`

export const BackImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 180px;
  width: 100%;
  transform: translate(0px, -105px);
  z-index: -2;
  ${({ theme }) => theme.media.above("large")} {
    position: absolute;
    max-width: 215px;
    transform: translate(0px, -170px);
  }
`

export const Detail = styled.div`
  position: absolute;
  width: 100%;
  max-width: 160px;
  transform: translate(174px, 200px);

  ${({ theme }) => theme.media.above("large")} {
    left: 0;
    bottom: 0;
    transform: translate(210px, 55px);
    max-width: 198px;
  }
`

export const DetailRight = styled.div`
  position: absolute;
  width: 100%;
  right: 0;
  display: none;

  ${({ theme }) => theme.media.above("large")} {
    bottom: 0;
    transform: translate(325px, 180px) rotate(25deg);
    max-width: 100px;
    display: block;
  }
`

export const DetailTop = styled.div`
  position: absolute;
  left: 20%;
  top: -50px;
  width: 100%;
  max-width: 100px;
  z-index: 999;

  ${({ theme }) => theme.media.above("large")} {
    display: block;
    right: -10%;
    bottom: -20px;
    max-width: 150px;
  }
`

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.above("large")} {
    flex-direction: row;
    position: relative;
  }
`

export const Content = styled.div`
  width: ${getColumnSize(12)};
  margin-left: ${getColumnSize(0)};

  ${({ theme }) => theme.media.above("large")} {
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(1)};
  }
`

export const Title = styled(MainTitle)`
  margin-bottom: 12px;
  text-transform: none;

  ${({ theme }) => theme.media.above("large")} {
    margin-bottom: 24px;
  }
`

export const TextContent = styled(Text)`
  margin-bottom: 24px;
  max-width: 100%;
  font-size: 28px;
  line-height: 1.35;
  letter-spacing: -0.28px;

  a {
    position: relative;
    display: inline-block;
    transition: ${({ theme }) => theme.transition.ease(200)};

    &:after {
      content: "";
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.grayscale.dark};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.grayscale.medium};
      &:after {
        background-color: ${({ theme }) => theme.colors.grayscale.medium};
      }
    }
  }

  ${({ theme }) => theme.media.above("large")} {
    max-width: ${getColumnSize(9)};
  }
`
