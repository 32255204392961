import { motion } from "framer-motion"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import getColumnSize from "../../utils/getColumnSize"
import { SubTitle, Text, Title } from "../UI/Typography"

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 64px;
  position: relative;
  margin: 0 auto;
  width: ${getColumnSize(12)};

  ${({ theme }) => theme.media.above("large")} {
    padding-top: 144px;
    width: calc(100% - 24px);
    max-width: 1440px;
  }
`

export const HeaderSection = styled.div`
  width: ${getColumnSize(12)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 64px;

  ${({ theme }) => theme.media.above("large")} {
    margin-bottom: 160px;
    margin-left: ${getColumnSize(1)};
    flex-direction: row;
  }
`

export const LeftColumn = styled.div`
  flex-direction: column;
`

export const RightColumn = styled.div`
  flex-direction: column;
  flex: 1;

  ${({ theme }) => theme.media.above("large")} {
    margin-left: ${getColumnSize(1.5)};
    margin-right: ${getColumnSize(2)};
  }
`

export const CustomTitle = styled(Title)`
  width: 100%;

  ${({ theme }) => theme.media.above("large")} {
    max-width: 380px;
    margin-right: ${getColumnSize(0.6)};
  }
`

export const Caption = styled(SubTitle)`
  text-transform: uppercase;
  margin: 0;
  color: ${({ theme }) => theme.colors.blue.default};
  font-weight: 600;
  margin-bottom: 12px;
  font-size: 12px;
`

export const TextContent = styled(Text)`
  font-size: 24px;
  line-height: 1.33;
  padding-top: 26px;
`

export const DescriptionContent = styled(Text)`
  font-size: 24px;
  line-height: 1.33;
  margin-top: 12px;
  font-weight: 600;
`

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
`

export const TimeLineSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-bottom: 64px;
  border-left: 1px solid ${({ theme }) => theme.colors.blue.default};
  ${({ theme }) => theme.media.above("large")} {
    padding-left: ${getColumnSize(1)};
    padding-bottom: 115px;
    flex-direction: row;
  }

  &:after {
    box-sizing: border-box;
    content: "";
    width: 9px;
    height: 9px;
    position: absolute;
    top: -4.5px;
    left: -5px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.blue.default};
    background-color: ${({ theme }) => theme.colors.grayscale.white};
  }

  &.present {
    &:after {
      background-color: ${({ theme }) => theme.colors.blue.default};
    }
  }

  &:last-child {
    border-left: none;
  }

  &:first-child {
    padding-top: 32px;
  }
`

export const TimeLineLabel = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.blue.default};
  border-radius: 4px;
  padding: 2px 4px;
  color: ${({ theme }) => theme.colors.blue.default};
  text-transform: uppercase;
  top: -8px;
  left: 16px;
  font-size: 14px;

  background-color: ${({ theme }) => theme.colors.blue.light};
  opacity: 0.9;

  &.present {
    background-color: ${({ theme }) => theme.colors.blue.default};
    color: ${({ theme }) => theme.colors.grayscale.white};
    opacity: 1;
  }
`

export const TimeLineSectionListItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: ${getColumnSize(4)};
  padding-left: 16px;
  margin-top: 64px;

  ${({ theme }) => theme.media.above("large")} {
    padding: 0;
    margin-top: 0;
  }
`

export const TimeLineSectionItem = styled(motion.div)`
  padding-left: 28px;
  display: flex;
  flex-direction: column;
  max-width: 367px;
  position: relative;
  margin-bottom: 40.5px;
  cursor: pointer;
  font-size: 18px;

  &.simple {
    cursor: default;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const TimeLineSectionItemIconHolder = styled.div`
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
`

export const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.colors.grayscale.inputBorder};
  margin-top: 12px;
`

export const ProgressBar = styled(motion.div)`
  width: 50%;
  background: ${({ theme }) => theme.colors.blue.default};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
`

export const Image = styled(Img)`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  max-height: 360px;
  flex: 1;
  margin-left: ${getColumnSize(1)};
  margin-top: -20px;
`

export const ImageHolder = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 32px;
  ${({ theme }) => theme.media.above("large")} {
    margin: 0;
  }
`

export const VideoHolder = styled.div`
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  max-height: 420px;
  max-width: 70%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 550px;
  }
  ${({ theme }) => theme.media.above("large")} {
    margin-left: ${getColumnSize(2)};
    margin-right: 0;
    max-width: 100%;
  }
`
