import * as React from "react"
import styled from "../styled"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

import getColumnSize from "../../utils/getColumnSize"
import { Container } from "../UI/Grid/Grid"
import { Text, Title } from "../UI/Typography"
import Form from "../Form/form"
import theme from "../Provider/theme"
import { motion } from "framer-motion"

const Content = styled.div`
  width: ${getColumnSize(12)};
  margin-left: ${getColumnSize(0)};
  ${({ theme }) => theme.media.above("large")} {
    width: ${getColumnSize(5)};
    margin-left: ${getColumnSize(1)};
  }
`
const Wrapper = styled.div`
  display: flex;
  margin: 80px 0 80px;
  flex-direction: column;

  ${({ theme }) => theme.media.above("large")} {
    margin: 91px 0 60px;
    flex-direction: row;
  }
`

const TitleContent = styled(Title)`
  margin-top: 0px;
  margin-bottom: 16px;
  font-weight: 600;

  span {
    font-size: 20px;
    position: relative;
    top: -3px;
    left: 2px;
  }

  ${({ theme }) => theme.media.above("large")} {
    margin-top: 109px;

    span {
      top: -15px;
      left: unset;
    }
  }
`

const TextContent = styled(Text)`
  margin-bottom: 16px;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 1.375;
  max-width: 405px;
`

const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 64px auto;
  ${({ theme }) => theme.media.above("large")} {
    max-width: 100%;
    margin-left: ${getColumnSize(1)};
    width: ${getColumnSize(4)};
    margin: 0 auto;
  }
`

const FormWrapper = styled(motion.div)`
  max-width: 405px;
`

const SuccessMessageWrapper = styled(motion.div)`
  border: 1px solid ${({ theme }) => theme.colors.grayscale.inputBorder};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  box-shadow: 0 4px 16px 0 rgba(83, 98, 124, 0.06);
  box-sizing: border-box;
  padding: 12px 16px;
  max-width: fit-content;
  display: flex;
`

const SuccessText = styled(Text)`
  color: ${({ theme }) => theme.colors.blue.default};
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 22px;
  margin-left: 8px;
`

const CheckCircle: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        fill="none"
        fill-rule="evenodd"
        stroke={theme.colors.blue.default}
        stroke-linecap="round"
        stroke-linejoin="round"
        transform="translate(1 1)"
      >
        <path d="M15.9989882,7.26850769 L15.9989882,8.00445906 C15.9969626,11.5471737 13.6649776,14.6665893 10.2676488,15.6710733 C6.87031997,16.6755574 3.21694748,15.3258251 1.2887241,12.3538248 C-0.639499284,9.38182451 -0.383238757,5.495536 1.91853777,2.80246045 C4.22031431,0.109384906 8.01928549,-0.74894241 11.2553016,0.692942104" />
        <polyline points="15.999 1.605 8 9.612 5.6 7.213" />
      </g>
    </svg>
  )
}

const PreFooter: React.FC = () => {
  const data: PreFooterType = useStaticQuery(query).contentYaml.prefooter
  const [subscribeSuccess, setSubscribeSuccess] = React.useState(false)
  return (
    <Container>
      <Wrapper>
        <Content>
          <TitleContent as="div">
            <div dangerouslySetInnerHTML={{ __html: data.title }} />
          </TitleContent>
          <TextContent>{data.text}</TextContent>

          {!subscribeSuccess && (
            <FormWrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Form onSuccess={() => setSubscribeSuccess(true)} />
            </FormWrapper>
          )}

          {subscribeSuccess && (
            <SuccessMessageWrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <CheckCircle />
              <SuccessText>{data.success_subscribe_message}</SuccessText>
            </SuccessMessageWrapper>
          )}
        </Content>
        <ImageWrapper>
          <Img fluid={data.image.childImageSharp.fluid} />
        </ImageWrapper>
      </Wrapper>
    </Container>
  )
}

interface PreFooterType {
  button_url: string
  button_text: string
  title: string
  text: string
  success_subscribe_message: string
  image: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

export const query = graphql`
  query PreFooter {
    contentYaml(homepage: { button: { ne: null } }) {
      prefooter {
        button_url
        button_text
        title
        text
        success_subscribe_message
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default PreFooter
