import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

import styled, { css } from "../styled"
import getColumnSize from "../../utils/getColumnSize"
import { Container } from "../UI/Grid/Grid"
import {
  Text,
  Title,
  Description,
  TestimonialName,
  TestimonialText,
} from "../UI/Typography"
import { Button } from "../UI"
import Icon from "../UI/Icon/Icon"
import LinkWrap from "../UI/Link"

const TopHalf = styled.div`
  position: relative;
`

const ClientsWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grayscale.dark};
  padding: 56px 0;

  ${({ theme }) => theme.media.above("large")} {
    padding: 120px 0;
    width: calc(100% - 24px);
    margin: 0 auto;
    border-radius: 8px;
    max-width: 1440px;
  }
`

const wrapperStyles = css`
  display: flex;
`

const UpperWrapper = styled.div`
  ${wrapperStyles};
  flex-direction: column-reverse;

  ${({ theme }) => theme.media.above("large")} {
    flex-direction: row;
  }
`
const Wrapper = styled.div`
  ${wrapperStyles};
`

const Content = styled.div`
  width: ${getColumnSize(12)};

  ${({ theme }) => theme.media.above("large")} {
    width: ${getColumnSize(4)};
    min-width: 405px;
  }
`

const MainTitle = styled(Title)`
  margin-bottom: 16px;
`

const MainText = styled(Text)`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.grayscale.lightMedium};
`

const CTA = styled(Button)`
  margin-bottom: 0;
`

const DecorationWrapper = styled.div`
  width: 100%;
  transform: unset;
  max-width: 600px;
  margin: -120px auto 48px;

  ${({ theme }) => theme.media.above("large")} {
    max-width: 858px;
    transform: translateX(200px);
    margin-top: -244px;
    margin-bottom: 0;
  }
`

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  padding-bottom: 32px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.grayscale.medium};

  ${({ theme }) => theme.media.above("large")} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 96px;
  }
`

const MiddleItem = styled.div`
  width: ${getColumnSize(12)};
  margin-left: ${getColumnSize(0)};
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.above("large")} {
    width: calc(33.3333% - 15px);
    margin-bottom: 0;
  }
`

const MiddleTitle = styled(Description)`
  font-weight: 500;
  line-height: 18px;
`

const MiddleLink = styled(LinkWrap)`
  display: flex;
  width: 100%;
`

const MiddleText = styled(Text)`
  position: relative;
  font-size: 0.875rem;
  line-height: 1.125rem;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const Bottom = styled.div`
  display: flex;
  padding-top: 32px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => theme.media.above("large")} {
    flex-direction: row;
  }
`

const BottomItem = styled.div`
  flex: unset;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  ${({ theme }) => theme.media.above("large")} {
    flex: 1 1 186px;
    justify-content: center;
  }
`

const BottomTitle = styled(TestimonialName)`
  position: relative;
`

const BottomText = styled(TestimonialText)`
  position: relative;
`

const Clients: React.FC = () => {
  const data: ClientsType = useStaticQuery(query)
  const content = data.contentYaml.clients
  return (
    <ClientsWrapper>
      <TopHalf>
        <Container>
          <UpperWrapper>
            <Content>
              <MainTitle color="white">{content.title}</MainTitle>
              <MainText>{content.text}</MainText>
              <CTA href={content.button_url}>{content.button_text}</CTA>
            </Content>
            <DecorationWrapper>
              <Img fluid={content.illustration.childImageSharp.fluid} />
            </DecorationWrapper>
          </UpperWrapper>
        </Container>
      </TopHalf>
      {content.published && (
        <Container>
          <Middle>
            {content.main_three.map(item => {
              return (
                <MiddleItem key={item.title}>
                  <MiddleLink to={item.url}>
                    <Wrapper>
                      <Icon asset={item.icon.childImageSharp.fluid} />
                      <TitleWrapper>
                        <MiddleTitle color="white">{item.title}</MiddleTitle>
                        <MiddleText color="grey">{item.link}</MiddleText>
                      </TitleWrapper>
                    </Wrapper>
                  </MiddleLink>
                  <MiddleText color="grey">{item.text}</MiddleText>
                </MiddleItem>
              )
            })}
          </Middle>
        </Container>
      )}
      {content.published && (
        <Container>
          <Bottom>
            {content.rest.map(rest => {
              return (
                <BottomItem key={rest.title}>
                  <MiddleLink to={rest.url}>
                    <Wrapper>
                      <Icon asset={rest.icon.childImageSharp.fluid} />
                      <TitleWrapper>
                        <BottomTitle color="white">{rest.title}</BottomTitle>
                        <BottomText color="grey">{rest.link}</BottomText>
                      </TitleWrapper>
                    </Wrapper>
                  </MiddleLink>
                </BottomItem>
              )
            })}
          </Bottom>
        </Container>
      )}
    </ClientsWrapper>
  )
}

interface ClientsType {
  contentYaml: {
    clients: {
      published: boolean
      button_text: string
      button_url: string
      text: string
      title: string
      illustration: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
      rest: Array<{
        link: string
        title: string
        url: string
        icon: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
      }>
      main_three: Array<{
        icon: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
        link: string
        text: string
        title: string
        url: string
      }>
    }
  }
}

export const query = graphql`
  query Clients {
    contentYaml(homepage: { button: { ne: null } }) {
      clients {
        published
        button_text
        button_url
        text
        title
        illustration {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        rest {
          link
          title
          url
          icon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        main_three {
          icon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          link
          text
          title
          url
        }
      }
    }
  }
`

export default Clients
