import * as React from "react"
import styled from "../styled"
import { graphql, useStaticQuery } from "gatsby"
import { motion, useViewportScroll, useTransform } from "framer-motion"

import getColumnSize from "../../utils/getColumnSize"
import { Container } from "../UI/Grid/Grid"
import { SubTitle, Title, Text } from "../UI/Typography"
import Img, { FluidObject } from "gatsby-image"
import AkashaBy from "../AkashaBy/AkashaBy"

const OurVisionWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grayscale.dark};
  padding: 64px 1.25rem;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  left: 50%;
  right: 50%;
  position: relative;

  ${({ theme }) => theme.media.above("small")} {
    padding-top: 64px;
    left: unset;
    right: unset;
    position: relative;
    margin: 0 auto;
    border-radius: 8px;
    width: ${getColumnSize(12)};
  }

  ${({ theme }) => theme.media.above("large")} {
    padding: 80px 0;
    width: calc(100% - 24px);
    max-width: 1440px;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.media.above("large")} {
    flex-direction: row;
  }
`

const DetailWrapper = styled.div`
  width: 100%;
  max-width: 80px;
  position: absolute;
  top: -150px;

  left: 0;
  z-index: 5;

  ${({ theme }) => theme.media.above("large")} {
    top: -170px;
    max-width: ${getColumnSize(1)};
  }
`

const ContentWrapper = styled.div`
  width: ${getColumnSize(12)};
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.above("large")} {
    margin-left: ${getColumnSize(1)};
    flex-direction: row;
  }
`

const TitleContent = styled(Title)`
  margin-top: 12px;
  margin-bottom: 16px;
  width: 100%;
  z-index: 1;
  span {
    position: relative;
    .highlight {
      &:after {
        position: absolute;
        top: 3.5px;
        left: -2px;
        right: -2px;
        bottom: 3px;
        background-color: ${({ theme }) => theme.colors.blue.default};
        content: "";
        z-index: -1;
        opacity: 0.4;
      }
    }
  }

  ${({ theme }) => theme.media.above("large")} {
    max-width: 380px;
    margin-right: ${getColumnSize(0.6)};
  }
`

const Caption = styled(SubTitle)`
  text-transform: uppercase;
  margin: 0;
  color: ${({ theme }) => theme.colors.grayscale.mediumLight};
  font-weight: 600;
  margin-bottom: 12px;
  font-size: 12px;
`

const TextContent = styled(Text)`
  margin-top: 0;
  color: ${({ theme }) => theme.colors.grayscale.lightMedium};

  p {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    position: relative;
    display: inline-block;
    transition: ${({ theme }) => theme.transition.ease(200)};

    &:after {
      content: "";
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      height: 1px;
      transition: ${({ theme }) => theme.transition.ease(200)};
      background-color: ${({ theme }) => theme.colors.grayscale.mediumLight};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.grayscale.medium};
      &:after {
        background-color: ${({ theme }) => theme.colors.grayscale.medium};
      }
    }
  }

  ${({ theme }) => theme.media.above("large")} {
    margin-right: ${getColumnSize(1)};
  }
`

interface Vision {
  contentYaml: {
    perks: {
      subtitle: string
      text: string
      title: string
      illustration: Array<{
        img: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
      }>
      detail: {
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }
  }
}

export const query = graphql`
  query OurVision {
    contentYaml(homepage: { button: { ne: null } }) {
      perks {
        subtitle
        text
        title
        illustration {
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        detail {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const TIMER_DELAY = 2000

const OurVision: React.FC = () => {
  const data: Vision = useStaticQuery(query)
  const content = data.contentYaml.perks

  const { scrollYProgress } = useViewportScroll()
  const moveDetail = useTransform(scrollYProgress, [0, 1], [50, -300])
  const rotateDetail = useTransform(scrollYProgress, [0, 1], [0, 100])

  const [displaySlide, setDisplaySlide] = React.useState(0)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setDisplaySlide(
        prevState => (prevState + 1) % content.illustration.length
      )
    }, TIMER_DELAY)

    return () => clearTimeout(timer)
  }, [content.illustration.length, displaySlide])

  return (
    <OurVisionWrapper>
      <Container>
        <Wrapper>
          <DetailWrapper>
            <motion.div
              style={{
                translateY: moveDetail,
                rotate: rotateDetail,
              }}
            >
              <Img fluid={content.detail.childImageSharp.fluid} />
            </motion.div>
          </DetailWrapper>
          <ContentWrapper>
            <TitleContent as="div" color="white">
              <Caption>{content.subtitle}</Caption>
              <span dangerouslySetInnerHTML={{ __html: content.title }} />
            </TitleContent>
            <TextContent as="div">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.contentYaml.perks.text,
                }}
              />
            </TextContent>
          </ContentWrapper>
        </Wrapper>
        <AkashaBy />
      </Container>
    </OurVisionWrapper>
  )
}

export default OurVision
