import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import * as S from "./OurJourney.css"
import { Container } from "../UI/Grid/Grid"
import { Link } from "../UI"
import theme from "../Provider/theme"
import { CSSProperties } from "styled-components"
import { FluidObject } from "gatsby-image"

const ChevronIcon: React.FC<{ color: CSSProperties["color"] }> = ({
  color,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fill-rule="evenodd">
        <rect width="20" height="20" />
        <polyline
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.667"
          points="0 10 5 5 0 0"
          transform="translate(8 5)"
        />
      </g>
    </svg>
  )
}

const TimeLineSection: React.FC<SectionProps> = ({
  label,
  items,
  present,
  dynamic,
  illustration,
  video,
}) => {
  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedIllustration, setSelectedIllustration] = useState(illustration)
  const [userInteracted, setUserInteracted] = useState(false)
  const itemDuration = 15

  const handlerOnclick = (index: number, userInteracted?: boolean) => {
    setSelectedItem(index)
    setUserInteracted(!!userInteracted)

    if (!items || !items[index] || !items[index].illustration) return

    setSelectedIllustration(items[index].illustration)
  }

  const onCompleteItemAnimation = () => {
    if (!items || userInteracted || selectedItem >= items.length - 1) return

    const nextIndex = selectedItem + 1
    setSelectedItem(nextIndex)
    handlerOnclick(nextIndex)
  }

  return (
    <S.TimeLineSection className={present ? "present" : ""} key={label}>
      <S.TimeLineLabel className={present ? "present" : ""}>
        {label}
      </S.TimeLineLabel>
      <S.TimeLineSectionListItems>
        {items &&
          items.map(({ text }, index) =>
            dynamic ? (
              <S.TimeLineSectionItem
                key={text}
                onClick={() => handlerOnclick(index, true)}
                animate={{
                  color:
                    index == selectedItem
                      ? theme.colors.grayscale.dark
                      : theme.colors.grayscale.mediumLight,
                }}
              >
                {text}

                <S.ProgressBarWrapper>
                  {selectedItem === index && (
                    <S.ProgressBar
                      animate={{ width: "100%" }}
                      initial={{ width: userInteracted ? "100%" : 0 }}
                      transition={{
                        default: { duration: itemDuration },
                      }}
                      onAnimationComplete={onCompleteItemAnimation}
                    />
                  )}
                </S.ProgressBarWrapper>

                <S.TimeLineSectionItemIconHolder>
                  <ChevronIcon
                    color={
                      index == selectedItem
                        ? theme.colors.blue.default
                        : theme.colors.grayscale.mediumLight
                    }
                  />
                </S.TimeLineSectionItemIconHolder>
              </S.TimeLineSectionItem>
            ) : (
              <S.TimeLineSectionItem key={text} className="simple">
                {text}
                <S.TimeLineSectionItemIconHolder>
                  <ChevronIcon color={theme.colors.blue.default} />
                </S.TimeLineSectionItemIconHolder>
              </S.TimeLineSectionItem>
            )
          )}
      </S.TimeLineSectionListItems>

      {selectedIllustration && !video && (
        <S.ImageHolder
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ default: { duration: 0.5 } }}
          key={selectedIllustration.childImageSharp.fluid.src}
        >
          <S.Image
            objectFit="contain"
            objectPosition="50% 50%"
            fluid={selectedIllustration.childImageSharp.fluid}
          />
        </S.ImageHolder>
      )}

      {video && (
        <S.VideoHolder>
          <video loop autoPlay muted playsInline>
            <source src={video.publicURL} type="video/mp4" />
          </video>
        </S.VideoHolder>
      )}
    </S.TimeLineSection>
  )
}

const OurJourney: React.FC = () => {
  const { contentYaml } = useStaticQuery<OurJourneyType>(query)
  const { ourJourney: content } = contentYaml
  return (
    <Container>
      <S.Wrapper>
        <S.HeaderSection>
          <S.LeftColumn>
            <S.Caption>{content.subtitle}</S.Caption>
            <S.CustomTitle>
              <span dangerouslySetInnerHTML={{ __html: content.title }} />
            </S.CustomTitle>
          </S.LeftColumn>

          <S.RightColumn>
            <S.TextContent>{content.text}</S.TextContent>
            <S.DescriptionContent>{content.description}</S.DescriptionContent>
          </S.RightColumn>
        </S.HeaderSection>

        <S.Timeline>
          {content.sections.map(sectionProps => (
            <TimeLineSection {...sectionProps} />
          ))}
        </S.Timeline>
      </S.Wrapper>
    </Container>
  )
}

export default OurJourney

interface IllustrationType {
  childImageSharp: {
    fluid: FluidObject
  }
}
interface SectionProps {
  label: string
  present?: boolean
  dynamic?: boolean
  illustration?: IllustrationType
  video?: {
    publicURL: string
  }
  items?: [
    {
      text: string
      illustration?: IllustrationType
    }
  ]
}

interface OurJourneyType {
  contentYaml: {
    ourJourney: {
      title: string
      subtitle: string
      text: string
      description: string
      sections: SectionProps[]
    }
  }
}

export const query = graphql`
  query OurJourney {
    contentYaml(homepage: { button: { ne: null } }) {
      ourJourney {
        title
        subtitle
        text
        description
        sections {
          label
          present
          dynamic
          illustration {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          video {
            publicURL
          }
          items {
            text
            illustration {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
