import * as React from "react"

import SEO from "../components/seo"
import Provider from "../components/Provider"
import Header from "../components/Header/Header"
import OurVision from "../components/OurVision/OurVision"
import YourWorld from "../components/YourWorld/YourWorld"
import Clients from "../components/Clients/Clients"
import PreFooter from "../components/PreFooter/PreFooter"
import Footer from "../components/Footer/Footer"
import Hero from "../components/Hero/Hero"
import CookieDisclaimer from "../components/CookieDisclaimer/cookieDisclaimer"
import OurJourney from "../components/OurJourney"
// import FAQ from "../components/FAQ"

const IndexPage = () => {
  return (
    <Provider>
      <SEO />
      <Header />
      <Hero />
      <OurVision />
      <OurJourney />
      <YourWorld />
      <Clients />
      {/* <FAQ /> */}
      <PreFooter />
      <CookieDisclaimer />
      <Footer />
    </Provider>
  )
}

export default IndexPage
